import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Default styling for toast
import config from '../configs.json';
import '../styles/Forms.css'; // Form-specific styles
import '../styles/Toast.css'; // Toast-specific styles

const AccountForm = ({ onNext }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cellPhone: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const { firstName, lastName, cellPhone, email, button } = config.forms.accountForm;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('/api/register/account_information', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.errors) {
        console.log(data.errors);
        setErrors(data.errors);
        Object.keys(data.errors).forEach((key) => {
          toast.error(data.errors[key]);
        });

      } else {
        toast.success('Account information submitted, redirecting to login page...');
        // Redirect to login page after 5 seconds
        setTimeout(() => {
          window.location.href = 'https://meurice.smrtapp.com/customer/';
        }, 5000);   
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error('An error occurred. Please try again.');
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="firstName">{firstName}:</label>
      <input type="text" id="firstName" name="firstName" value={formData.firstName} autoComplete="given-name" onChange={handleChange} required />

      <label htmlFor="lastName">{lastName}:</label>
      <input type="text" id="lastName" name="lastName" value={formData.lastName} autoComplete="family-name" onChange={handleChange} required />

      <label htmlFor="cellPhone">{cellPhone}:</label>
      <input type="tel" id="cellPhone" name="cellPhone" value={formData.cellPhone} autoComplete="tel" onChange={handleChange} required /> 

      <label htmlFor="email">{email}:</label>
      <input type="email" id="email" name="email" value={formData.email} autoComplete="email" onChange={handleChange} required />

      <label htmlFor="password">Password:</label>
      <input type="password" id="password" name="password" value={formData.password} autoComplete="new-password" onChange={handleChange} required />

      <button type="submit">{button}</button>
      <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="toast-container" // Add your custom class here
      />
    </form>
  );
};

export default AccountForm;
