import React from 'react';
import logo from '../assets/meurice.png'; // Company logo
import '../styles/Header.css'; // If you have specific styles for the header
import config from '../configs.json';

const Header = () => {
  const { companyName } = config.header;
  return (
    <header className="header">
      <img src={logo} alt={companyName} className="logo" />
    </header>
  );
};

export default Header;
