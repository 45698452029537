import React from 'react';
import config from '../configs.json';
import '../styles/Footer.css'; // Footer-specific styles

const Footer = () => {
  const { line1, line2, line3 } = config.footer;
  return (
    <footer>
      <span className="footer-text">{line1}</span>
      <span className="footer-text">{line2}</span>
      <span className="footer-text">{line3}</span>
    </footer>
  );
};

export default Footer;
