import React, { useState } from 'react';
import AccountForm from './components/AccountForm';
import Footer from './components/Footer';
import Header from './components/Header';
import './styles/App.css'; // Global styles

const App = () => {
  const [step, setStep] = useState(1);

  const handleSuccess = () => {
    window.location.href = 'https://meurice.smrtapp.com/customer/';
  };

  return (
    <div className="App">
      <Header />
      {step === 1 && <AccountForm onSuccess={handleSuccess} />}
      <Footer />
    </div>
  );
};

export default App;
